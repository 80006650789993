<template>
  <div class="spinner-container d-flex">
    <KnokWelcomeLogo class="spinner-logo"></KnokWelcomeLogo>
  </div>
</template>

<script>
import KnokWelcomeLogo from '../assets/img/knoknok-welcome.svg?inline'
export default {
  name: 'Spinner',
  components: {
    KnokWelcomeLogo
  }
}
</script>

<style scoped lang="scss">
.spinner-container{
  position: fixed;
  top:0;
  left: 0;
  padding: 4rem;
  width: calc(100% - 8rem);
  height: calc(100vh - 8rem);
  z-index: 100;
  background-color: #000000;
  align-items: center;
  justify-content: center;
  .spinner-logo{
    max-width: 500px;
    animation: pulse 2s ease-in-out infinite;
  }
}
</style>
