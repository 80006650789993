<template>
    <form class="contact-form">
        <div class="row">
            <div class="col-6 d-flex flex-column">
                <input v-model="name" type="text" name="name" id="name" required placeholder="Nombre y Apellido">
            </div>
            <div class="col-6 d-flex flex-column">
                <input v-model="email" type="email" name="email" id="email" required placeholder="email@dominio.com">
            </div>
        </div>
        <div class="row">
            <div class="col-12  d-flex flex-column">
                <textarea v-model="message" class="message" name="textarea" id="textarea" required placeholder="Ingresa tus comentarios..."></textarea>
            </div>
        </div>
        <div class="row d-flex justify-center align-center" v-if="statusmail!=''">
            <span :class="['status', statusmailclass]">
                {{statusmail}}
            </span>
        </div>
        <div class="row d-flex justify-center align-center">
            <div class="button-container" @click="sendContact()">
             <MagneticButton id="contact-btn" class="circle-button p-absolute" text="GO" :size='50' :distance='8'></MagneticButton>
            </div>
        </div>
    </form>
</template>
<script>
import MagneticButton from '../components/MagneticButton'
import axios from 'axios'
import { EventBus } from '@/event-bus'
export default {
  name: 'ContactForm',
  components: {
    MagneticButton
  },
  data () {
    return {
      name: '',
      email: '',
      message: '',
      statusmail: '',
      statusmailclass: ''
    }
  },
  methods: {
    sendContact () {
      if (this.formValidate()) {
        EventBus.$emit('showSpinner', true)
        axios.post('https://knoknok.com.ar/api/v1/email.php', {
          nombre: this.name,
          email: this.email,
          mensaje: this.message
        }, {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*'
          }
        }).then((response) => {
          if (response.data.status === 200) {
            this.statusmail = response.data.response
            this.statusmailclass = 'correct'
          }
          if (response.data.status === 400 || response.data.status === 500) {
            this.statusmail = response.data.response
            this.statusmailclass = 'error'
          }
          EventBus.$emit('showSpinner', false)
        })
          .catch((err) => {
            console.log(err)
            EventBus.$emit('showSpinner', false)
          })
      } else {
        this.statusmail = 'Por favor complete todos los campos correctamente'
        this.statusmailclass = 'error'
      }
    },
    formValidate () {
      const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      return reg.test(this.email) && this.name !== '' && this.message !== ''
    }
  }
}
</script>
<style scoped lang="scss">
.contact-form{
    width: 600px;
    margin-bottom: 4rem;
    .row{
        margin: 0 -15px;
    }
    input{
        margin-bottom: 1rem;
        color: #ffffff;
        font-size: 14px;
        line-height: 34px;
        padding-left: 10px;
        border: none;
        transition: all 0.3s ease-in-out;
        background-color: #ffffff22;
        border-bottom: 1px solid transparent;
        &:focus{
            border-bottom: 1px solid $main-color;
        }
    }
    textarea{
        height: 80px;
        margin-bottom: 1rem;
        color: #ffffff;
        font-size: 14px;
        line-height: 34px;
        padding-left: 10px;
        resize: none;
        border: none;
        background-color: #ffffff22;
        border-bottom: 1px solid transparent;
        transition: all 0.3s ease-in-out;
        &:focus{
            border-bottom: 1px solid $main-color;
        }
    }
    .status{
        text-align: center;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        &.error{
            color: #f9635c;
        }
        &.correct{
            color: #5cf9bd;
        }
    }
    .button-container{
        width: 50px;
    }
}
@media only screen and (max-width: 800px){
    .contact-form{
        width: 100%;
        .row{
            flex-direction: column;
        }
    }
}
</style>
