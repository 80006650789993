<template>
    <section id="travel-work">
        <div class="hero-container fullScreen">
            <div class="wrapper tw-content text-content text-center">
                <span>{{$t('tw.manifest')}}<br><br></span>
                <p>{{$t('tw.manifest text')}}</p>
            </div>
            <img class="tw-word p-absolute" src="../assets/img/travel-work.svg">
        </div>
        <div class="travelWork-item">
            <div class="wrapper text-content text-center">
                <h3>{{$t('tw.itemTitle1')}}</h3>
                <p>{{$t('tw.itemParagraph1')}}</p>
            </div>
            <div class="parallax-container d-flex align-center justify-center">
                <img v-parallax="0.2" src="../assets/img/travel-work-img-1.jpg" width="100%">
            </div>
       </div>
       <div class="travelWork-item">
           <div class="wrapper text-content text-center">
               <h3>{{$t('tw.itemTitle2')}}</h3>
               <p>{{$t('tw.itemParagraph2')}}</p>
           </div>
            <div class="parallax-container d-flex align-center justify-center">
                <img v-parallax="0.2" src="../assets/img/travel-work-img-2.jpg" width="100%">
            </div>
       </div>
       <div class="travelWork-item">
           <div class="wrapper text-content text-center">
               <h3>{{$t('tw.itemTitle3')}}</h3>
               <p>{{$t('tw.itemParagraph3')}}</p>
           </div>
           <div class="parallax-container d-flex align-center justify-center">
                <img v-parallax="0.2" src="../assets/img/travel-work-img-3.jpg" width="100%">
            </div>
       </div>
        <div class="travelWork-item">
            <div class="wrapper text-content text-center">
                <h3>{{$t('tw.itemTitle4')}}</h3>
                <p>{{$t('tw.itemParagraph4')}}</p>
                <span>{{$t('tw.itemSubtitle4')}}</span>
            </div>
            <div class="parallax-container d-flex align-center justify-center">
                <img v-parallax="0.2" src="../assets/img/travel-work-img-4.jpg" width="100%">
            </div>
            <span class="ph-credits p-absolute">Imagen: <a href="http://instagram.com/gastonroel" class="ph-credits" target="_blank">Ph Gastón Roel</a></span>
        </div>
    </section>
</template>
<script>
export default {
  name: 'TravelWork',
  components: {
  },
  mounted () {
  }
}
</script>
<style scoped lang="scss">
#travel-work{
    #trabajo{
        height: 10px;
    }
    .hero-container{
        position: relative;
        width: 100%;
        background-image: url('../assets/img/travel-work_bg.jpg');
        background-position: center;
        background-size: cover;
        .tw-content{
            padding-top: 10%;
        }
        span, p{
            color: #ffffff;
            font-size: 24px;
        }
        .tw-word{
            bottom: 50px;
        }
    }

    .travelWork-item{
        padding: 12rem 0 0;
        background-color: #000000;
        .ph-credits{
            left: 50%;
            transform: translateX(-50%);
        }
        .wrapper{
            padding-bottom: 12rem!important;
        }
        h3{
            font-size: 42px;
            color: white;
            white-space: break-spaces;
        }
        span{
            color: white;
            font-size: 30px;
            margin-top: 1rem;
        }
        p{
            color: white;
            margin: 2rem 0 1rem;
            font-size: 18px;
            white-space: break-spaces;
        }
        .parallax-container{
            overflow: hidden;
            height: 100vh;
        }
    }
}
@media only screen and (max-width: 800px){
    #travel-work{
        .hero-container{
            p{
                font-size: 22px;
            }
        }
        .travelWork-item{
            padding: 6rem 0 0;
            h3{
                font-size: 24px;
                line-height: 1.4;
            }
            p{
                font-size: 16px;
            }
            .wrapper{
                padding-bottom: 6rem!important;
            }
            .parallax-container{
                height: inherit;
            }
        }
    }
}
</style>
