<template>
    <footer id="footer" class="d-flex justify-center align-center">
        <div id="contact" class="footer-container d-flex justify-center align-center flex-column">
            <h3 class="contact-title dmserif">contact</h3>
            <div class="contact-form-container d-flex align-center justify-center flex-column">
                <transition name="fade" appear>
                    <ContactForm v-if="showContactForm"></ContactForm>
                </transition>
                <div class="button-container p-relative" v-if="!showContactForm" @click="showContact()">
                    <MagneticButton id="contact-button" class="circle-button p-absolute" text="GO" :size='50' :distance='8'></MagneticButton>
                </div>
            </div>
            <div class="contact-info-container row flex-m-col">
                <div class="col-4 footer-logo-container">
                    <Logo class="logo"></Logo>
                </div>
                <div class="col-8 d-flex flex-column">
                    <p>Travel Consulting</p>
                    <p>Brandstudio / Knokfanzine</p>
                    <span>®All Rights Reserved . 2021 Buenos Aires Argentina. Development by <a href="https://daptee.com.ar" target="_blank">Daptee</a></span>
                    <a href="http://instagram.com/mundoknoknok" target="_blank" class="instagram-social d-flex align-center customLink">
                        <InstagramIcon class="instagram-icon"></InstagramIcon><span>mundoknoknok</span>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import Logo from '../assets/img/knoknok_logo.svg?inline'
import InstagramIcon from '../assets/img/instagram-icon.svg?inline'
// import { EventBus } from '../event-bus'
import MagneticButton from '../components/MagneticButton'
import ContactForm from '@/components/ContactForm'
export default {
  name: 'Footer',
  data () {
    return {
      showContactForm: false
    }
  },
  components: {
    Logo,
    InstagramIcon,
    MagneticButton,
    ContactForm
  },
  methods: {
    showContact () {
      this.showContactForm = !this.showContactForm
    }
  }
}
</script>
<style scoped lang="scss">
#footer{
    background-color: #000000;
    min-height: 100vh;
    .footer-container{
        .contact-title{
            font-size: 160px;
            color: white;
            text-transform: inherit;
        }
        .contact-form-container{
            min-height: 200px;
            width: 200px;
            padding: 2rem 0;
            position: relative;
        }
        .button-container{
            width: 50px;
            height: 50px;
        }
        .contact-info-container{
            margin-left: 110px;
            .logo{
                width: 110px;
            }
            p, span, a{
                color: white;
                white-space: nowrap;
                line-height: 150%;
            }
            p{
                font-weight: bold;
            }
            .instagram-social{
                margin-top: 2rem;
                .instagram-icon{
                    width: 20px;
                    margin-right: 15px;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px){
    #footer{
        min-height: inherit;
        padding: 6rem 0;
        .footer-container{
            .contact-title{
                font-size: 80px;
            }
            .contact-form-container{
                margin-top: 4rem;
                width: 80%;
                min-height: inherit;
            }
            .contact-info-container{
                margin: 4rem 0 0;
                padding: 0 15px;
                text-align: center;
                span{
                    white-space: inherit;
                }
                .footer-logo-container{
                    margin-bottom: 2rem;
                }
                .instagram-social{
                    justify-content: center;
                }
            }
        }
    }
}
</style>
