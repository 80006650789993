<template>
  <div id="app" :class="{loading: loading}">
    <transition name="slide-down">
      <Welcome v-if="loading"></Welcome>
    </transition>
    <Spinner v-if="showSpinner" />
    <Header @changeLanguage="changeLanguage"></Header>
    <CustomCursor v-if="!isMobile || !isSafari"></CustomCursor>
    <router-view/>
    <!-- <transition name="fade" appear>
      <Modal v-if="showModal"></Modal>
    </transition> -->
  </div>
</template>
<script>
import Welcome from './components/Welcome'
import Header from './components/Header'
// import Modal from './components/Modal'
// import { EventBus } from './event-bus'
import CustomCursor from './components/CustomCursor'
import Spinner from '@/components/Spinner'
import { EventBus } from './event-bus'
import { isMobile, isSafari } from 'mobile-device-detect'
export default {
  name: 'App',
  components: {
    Welcome,
    Spinner,
    Header,
    // Modal,
    CustomCursor
  },
  data () {
    return {
      loading: true,
      showSpinner: false,
      isMobile,
      isSafari
      // showModal: false
    }
  },
  methods: {
    changeLanguage (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        const to = this.$router.resolve({ params: { locale } })
        this.$router.push(to.location)
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 2500)

    EventBus.$on('showSpinner', (show) => {
      this.showSpinner = show
    })
  },
  watch: {
    $route (to, from) {
      EventBus.$emit('showNavbar', true)
    }
  }
}
</script>

<style lang="scss">
#app.loading{
  position: fixed;
}
</style>
