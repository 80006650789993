<template>
  <div class="d-flex flex-row fullScreen knockfanzine-page-container flex-m-col" v-if="data">
<!--    <div class="knockfanzine-page-img" :style="{'background-image': 'url(' + require('@/assets/img/portada_bg.jpg') + ')'}"></div>-->
    <div class="knockfanzine-page-img d-flex flex-column"
         :style="{'background-image': 'url(' + data.thumbnail_images.full.url + ')'}">
      <div class="overlay">
        <div class="post-content p-absolute">
          <span class="date">{{friendlyDate(data.date)}}</span>
          <h2 class="title">{{data.title}}</h2>
          <span class="author">Autor: {{data.author.name}}</span>
          <span class="ph-image">Imagen: {{data.custom_fields.phimage[0]}}</span>
          <div class="prev-next-container d-flex flex-row">
            <router-link :to="{name: 'KnokfanzineItem', params: {knokfanzineSlug: previous_slug}}" v-if="previous_slug !== ''">ANTERIOR</router-link>
            <router-link :to="{name: 'KnokfanzineItem', params: {knokfanzineSlug: mext_slug}}" v-if="mext_slug !== ''">PROXIMO</router-link>
            <router-link :to="{name: 'Blog'}" class="close-button">CERRAR</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="knockfanzine-page-content d-flex flex-column">
      <div class="page-content" v-html="data.content"></div>
      <div class="footer-content d-flex justify-between">
        <div class="tags">{{data.tags.title}}</div>
        <a class="share" @click="share()">COMPARTIR</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { EventBus } from '@/event-bus'
import { isMobile } from 'mobile-device-detect'

moment.locale('es')

export default {
  name: 'KnokfanzineItem',
  data () {
    return {
      data: {
        date: '',
        title: '',
        author: {
          name: ''
        },
        custom_fields: {
          phimage: ''
        },
        thumbnail_images: {
          full: {
            url: ''
          }
        },
        tags: {
          title: ['']
        },
        isMobile
      },
      previous_slug: '',
      mext_slug: ''
    }
  },
  methods: {
    friendlyDate (date) {
      const momentDate = moment(date)
      return momentDate.format('DD') + ' ' + momentDate.format('MMMM') + ' ' + momentDate.format('YYYY')
    },
    getPostBySlug (slug) {
      EventBus.$emit('showSpinner', true)
      axios.get('https://knoknok.com.ar/admin/api/get_post?slug=' + slug, {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*'
        }
      }).then((response) => {
        this.data = response.data.post
        this.setPreviousSlug(response.data)
        this.setNextSlug(response.data)
        EventBus.$emit('showSpinner', false)
      })
        .catch((err) => {
          console.log(err)
          EventBus.$emit('showSpinner', false)
        })
    },
    setPreviousSlug (json) {
      this.previous_slug = ''
      if (json.previous_url !== undefined) {
        const urlParts = json.previous_url.split('/')
        this.previous_slug = urlParts[(urlParts.length - 2)] // MENOS 2 PORQUE LA URL TERMINA EN /
      }
    },
    setNextSlug (json) {
      this.mext_slug = ''
      if (json.next_url !== undefined) {
        const urlParts = json.next_url.split('/')
        this.mext_slug = urlParts[(urlParts.length - 2)] // MENOS 2 PORQUE LA URL TERMINA EN /
      }
    },
    share () {
      navigator.share({
        title: this.data.title,
        url: window.location.href
      })
    }
  },
  mounted () {
    this.getPostBySlug(this.$route.params.knokfanzineSlug)
    if (!isMobile) {
      EventBus.$emit('showNavbar', false)
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        if (to.path !== from.path) {
          const toParts = to.path.split('/')
          const slug = toParts[(toParts.length - 1)]
          this.getPostBySlug(slug)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/knockfanzine-item-html";

  .knockfanzine-page-container {
    .knockfanzine-page-img {
      width: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .overlay {
        width: 100%;
        height: 100%;
        z-index: 9;
        position: relative;
        .post-content{
          bottom: 4rem;
          right: 5.2rem;
          width: calc(100% - 10.4rem);
          .title {
            font-size: 3rem;
            text-transform: inherit;
            margin-bottom: 1rem;
            line-height: 1;
          }
          .date, .author, .ph-image {
            color: $medium-gray;
            font-size: 0.8rem;
          }
          .date{
            line-height: 3rem;
          }
          .ph-image{
            margin-left: 1rem;
          }
          .prev-next-container {
            margin-top: 4rem;
            .close-button{
              position: absolute;
              right: 0;
            }
            a {
              color: $medium-gray;
              font-family: 'Roboto';
              font-weight: 400;
              &:first-child {
                margin-right: 4rem;
              }
            }
          }
        }
      }
    }
    .knockfanzine-page-content {
      background-color: #FFF;
      width: calc(50% - 8rem);
      padding: 4rem;
      overflow: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      height: calc(100% - 8rem);
      .footer-content{
        position: fixed;
        bottom: 0;
        left: 50%;
        padding: 4rem;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
        width: calc(50% - 9rem);
        .tags{
          text-transform: uppercase;
        }
      }
    }
  }
@media only screen and (max-width: 800px){
  .knockfanzine-page-container{
    height: inherit;
    .knockfanzine-page-img{
      width: 100%;
      height: calc(100vh - 100px);
      .overlay{
        .post-content{
          bottom: 3rem;
          left: 3rem;
          width: calc(100% - 6rem);
          .title{
            font-size: 2.2rem;
          }
        }
      }
    }
    .knockfanzine-page-content{
      padding: 3rem;
      width: calc(100% - 6rem);
      .footer-content{
        position: relative;
        padding: 0;
        width: 100%;
        left: 0;
        padding-top: 2rem;
      }
    }
  }
}
</style>
