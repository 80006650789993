<template>
  <div class="knockfanzine-container d-flex flex-row justify-start align-start p-relative">
    <div class="wrapper">
      <div class="d-flex flex-column knockfanzine-news-container">
        <NewItem v-for="(newItem, index) in news" :data="newItem" :key="'knokfanzine-item-' + index"></NewItem>
      </div>
    </div>
    <span class="ph-credits p-absolute">Imagen: <a href="https://www.instagram.com/luciano.bacchi.foto/" class="ph-credits" target="_blank">Luciano Bacchi</a></span>
  </div>
</template>

<script>
import axios from 'axios'
import NewItem from '@/components/Knokfanzine/NewItem'
import { EventBus } from '@/event-bus'

export default {
  name: 'Blog',
  data () {
    return {
      news: []
    }
  },
  components: {
    NewItem
  },
  methods: {
    getNews () {
      EventBus.$emit('showSpinner', true)
      axios.get('https://knoknok.com.ar/admin/api/get_recent_posts/', {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*'
        }
      }).then((response) => {
        if (response.status === 200) {
          this.news = response.data.posts
        }
        EventBus.$emit('showSpinner', false)
      })
        .catch((err) => {
          console.log(err)
          EventBus.$emit('showSpinner', false)
        })
    }
  },
  mounted () {
    this.getNews()
  }
}
</script>

<style lang="scss">
.knockfanzine-container {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/portada_knockfanzine.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .wrapper {
    padding-top: 16rem;
    .knockfanzine-news-container{
      padding: 0 1.2rem;
    }
  }
  .ph-credits{
    bottom: 4rem;
    right: 4rem;
  }
  //padding-top: 16rem;
  //width: 50%;
}
@media only screen and (max-width: 800px){
  .knockfanzine-container{
    .wrapper{
      padding-top: 12rem;
    }
    .ph-credits{
      bottom: 2rem;
    }
  }
}
</style>
