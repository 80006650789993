import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/i18n'
import VueCarousel from 'vue-carousel'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueParallaxJs from 'vue-parallax-js'
// import VueGtag from 'vue-gtag'

Vue.config.productionTip = false
Vue.use(VueCarousel)
Vue.use(VueSmoothScroll, {
  duration: 1000,
  updateHistory: false
})
Vue.use(VueParallaxJs)
// Vue.use(VueGtag, {
//   config: {
//     id: 'G-ETK0WQBV60',
//     params: {
//       send_page_view: true
//     }
//   }
// })
// Vue.use(VueAnalytics, {
//   id: 'G-ETK0WQBV60',
//   router
// })

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
