<template>
    <a @click="toggleMenu()" class="toggle-menu mobile" :class="{active: toggleicon}"><span></span></a>
</template>
<script>
export default {
  name: 'ToggleMenu',
  props: ['toggleicon'],
  methods: {
    toggleMenu () {
      this.$emit('show-mobile-nav')
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-menu{
  display: block;
  padding: 20px;
  width: 28px;
  height: 22px;
  z-index: 1;
  span:after, span:before{
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
  }
  span:after{
    top: 18px;
  }
  span{
    position: relative;
    display: block;
  }
  span, span:after, span:before{
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px;
  }
  &.active span{
    background-color: transparent;
  }
  &.active span:before{
    transform: rotate(45deg) translate(-1px, 0px);
  }
  &.active span:after{
    transform: rotate(-45deg) translate(6px, -7px);
  }
}
</style>
