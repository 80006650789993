<template>
    <carousel :perPageCustom="[[300, 1.2], [800, 2.5]]" :mouse-drag="true" :paginationEnabled="false"
                :navigationEnabled="!isMobile" :autoplay="!isMobile" :loop="true" :autoplayTimeout="5000"
                navigationNextLabel=">" navigationPrevLabel="<" class="carousel">
        <slide class="slide-item"><img src="../assets/img/Geo-Vezzani-1.jpg"></slide>
        <slide class="slide-item"><img src="../assets/img/Geo-Vezzani-2.jpg"></slide>
        <slide class="slide-item"><img src="../assets/img/Geo-Vezzani-3.jpg"></slide>
        <slide class="slide-item"><img src="../assets/img/Geo-Vezzani-4.jpg"></slide>
        <slide class="slide-item"><img src="../assets/img/Geo-Vezzani-5.jpg"></slide>
    </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import { isMobile } from 'mobile-device-detect'
export default {
  name: 'CarouselGallery',
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      isMobile
    }
  }
}
</script>

<style lang="scss">
.carousel{
    width: 100%;
    .slide-item{
        margin: 0 5px;
        max-width: calc(40% - 10px)!important;
    }
    .slide-item:first-child{
        margin-left: 0px;
    }
    .slide-item:last-child{
        margin-right: 0px;
    }

    .VueCarousel-navigation {
        .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
            opacity: 0;
            background-color: rgba(255,255,255,0.5);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 0.7rem;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
            font-size: 20px;
            font-weight: 200;
            font-family: monospace;
            line-height: 20px;
            &:focus{
                outline: none!important;
            }
        }
        .VueCarousel-navigation-prev{
            left: 100px;
        }
        .VueCarousel-navigation-next{
            right: 100px;
        }
    }
    &:hover{
        .VueCarousel-navigation{
            .VueCarousel-navigation-prev, .VueCarousel-navigation-next{
                opacity: 1;
            }
        }
    }
}
@media only screen and (max-width: 800px){
    .carousel{
        .slide-item{
            max-width: calc(100% - 10px)!important;
        }
    }
}
</style>
