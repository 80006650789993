<template>
  <div class="new-item d-flex flex-column">
    <router-link :to="{name: 'KnokfanzineItem', params: {knokfanzineSlug: data.slug}}">
      <h2 class="new-item-tit">{{data.title}}</h2>
    </router-link>
    <span class="new-item-author">Author: {{data.author.name}}</span>
  </div>
</template>

<script>
export default {
  name: 'NewItem',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .new-item {
    padding: 1rem 1rem 1rem 0;
    border-bottom: 1px solid $medium-gray;
    width: 50%;
    .new-item-tit {
      font-size: 2rem;
      text-transform: inherit;
      line-height: 1;
    }
    .new-item-author {
      font-size: 0.8rem;
      color: $medium-gray;
      margin-top: 1rem;
    }
  }
@media only screen and (max-width: 800px){
  .new-item{
    width: calc(100% - 1rem);
  }
}
</style>
