<template>
    <section class="hero fullScreen d-flex">
        <div class="hero-content wrapper d-flex row flex-m-col">
            <div class="col-6 p-relative">
                <TravelConsulting class="travel-consulting p-absolute"></TravelConsulting>
            </div>
            <div class="col-6 hero-phrase">
                <h2>{{$t('hero.phrase1')}}</h2>
                <h2>{{$t('hero.phrase2')}}</h2>
                <h2>{{$t('hero.phrase3')}}</h2>
                <span class="ph-credits">Imagen: <a href="https://www.instagram.com/luciano.bacchi.foto/" class="ph-credits" target="_blank">Luciano Bacchi</a></span>
            </div>
        </div>
    </section>
</template>

<script>
import TravelConsulting from '../assets/img/knoknok_travel-consulting.svg?inline'
export default {
  name: 'Hero',
  components: {
    TravelConsulting
  }
}
</script>

<style scoped lang="scss">
.hero{
    background-color: $dark-blue;
    background-image: url('../assets/img/portada_bg.jpg');
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    .hero-content{
        align-items: center;
        padding-bottom: 5rem;
        .travel-consulting{
            height: 150px;
            right: 100px;
            top: 50%;
            transform: translateY(-50%);
        }
        .hero-phrase{
            margin-top: 17rem;
            h2{
                font-family: 'Roboto';
                font-weight: 200;
                color: white;
                font-size: 50px;
                white-space: nowrap;
                text-transform: inherit;
            }
            .ph-credits{
                font-size: 1rem;
                line-height: 2rem;
                color: #ffffff;
                padding-left: 2px;
            }
        }
    }
}

@media only screen and (max-width: 800px){
    .hero{
        .hero-content{
            .travel-consulting{
                height: 110px;
                position: relative;
                transform: inherit;
                right: inherit;
                margin-bottom: 2rem;
            }
            .hero-phrase{
                margin-top: 0rem;
                h2{
                    font-size: 28px;
                    line-height: 1.5;
                }
            }
        }
    }
}
</style>
