import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Knokfanzine from '../views/Knokfanzine.vue'
import KnokfanzineItem from '@/views/KnokfanzineItem'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: { render (c) { return c('router-view') } },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale.split('/')[0]
      if (locale !== 'es' && locale !== 'en') {
        let path = to.path
        if (locale.length === 2) {
          path = to.path.replace('/' + locale, '')
        }
        const newUrl = 'es' + path
        return next(newUrl)
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'knokfanzine',
        name: 'Blog',
        component: Knokfanzine
      },
      {
        path: 'knokfanzine/:knokfanzineSlug',
        name: 'KnokfanzineItem',
        component: KnokfanzineItem
      }
    ]
  },
  {
    path: '*',
    redirect: to => {
      return 'es'
    }
  }
]

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      if (to.hash === '#travel-work') {
        return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
      } else {
        return window.scrollTo({ top: document.querySelector(to.hash).offsetTop - 100, behavior: 'smooth' })
      }
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  }
})

export default router
