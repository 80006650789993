<template>
    <div class="customCursor">
        <div class="cursor"></div>
        <div class="drag-carousel">Drag & Drop</div>
    </div>
</template>

<script>
export default {
  name: 'CustomCursor',
  data () {
    return {
      cursor: '',
      links: '',
      carousel: ''
    }
  },
  methods: {
    handleEvent () {
      window.addEventListener('mousemove', (e) => this.mouseTrack(e))
      this.links.forEach((i) => {
        i.addEventListener('mouseover', this.mouseLink)
        i.addEventListener('mouseout', this.mouseLinkout)
      })
      // this.carousel.forEach((i) => {
      //   i.addEventListener('mouseover', this.carouselIn)
      //   i.addEventListener('mouseout', this.carouselOut)
      // })
    },
    mouseTrack (e) {
      this.cursor.style.left = `${e.x}px`
      this.cursor.style.top = `${e.y}px`
    },
    mouseLink () {
      this.cursor.classList.add('linkOver')
    },
    mouseLinkout () {
      this.cursor.classList.remove('linkOver')
    }
    // carouselIn () {
    //   this.cursor.classList.add('carouselOver')
    // },
    // carouselOut () {
    //   this.cursor.classList.remove('carouselOver')
    // }
  },
  mounted () {
    this.cursor = document.querySelector('.customCursor')
    this.links = document.querySelectorAll('.customLink')
    // this.carousel = document.querySelectorAll('.carousel-gallery')
    this.handleEvent()
  }
}
</script>

<style scoped lang="scss">
.customCursor{
    position: fixed;
    width: 100px;
    height: 100px;
    display: flex;
    z-index: 10000001;
    justify-content: center;
    transform: translate(-50px, -50px);
    pointer-events: none;
    transition: all .1s;
    -webkit-transition: all .1s;
    .cursor{
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        transform: scale(1) translateY(-50%);
        background-color: $main-color;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        transition: all .5s cubic-bezier(.37,.01,0,.98);
        -webkit-transition: all .5s cubic-bezier(.37,.01,0,.98);
        transform-origin: center;
    }
    &.linkOver .cursor{
        transition: all .3s;
        opacity: .3;
        width: 60px;
        height: 60px;
    }
    &.carouselOver .cursor{
        background-color: #ffffff7c;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    &.carouselOver .drag-carousel{
        opacity: 1;
        visibility: visible;
    }
    .drag-carousel{
        -webkit-transition: all .3s;
        transition: all .3s;
        width: 100%;
        height: 100%;
        border-radius: 50rem;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transform-origin: center;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
}
</style>
