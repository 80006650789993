<template>
    <header class="header" :class="{'sticky':showLogo}">
        <div class="header-container d-flex wrapper">
            <div class="logo-container">
                <router-link :to="{name: 'Home'}" class="customLink">
                <transition v-if="showLogo || isInBlog" name="fade" appear>
                    <Logo class="logo"></Logo>
                </transition>
                </router-link>
            </div>
            <ToggleMenu @show-mobile-nav="showMobileNavbar" :toggleicon="showMobileNav"/>
            <div class="nav-container desktop" :class="{'mobile-nav': showMobileNav}" v-if="showNavbar">
              <ul class="nav d-flex flex-m-col">
                  <!-- <li class="customLink"><a :href="getFullLink('#we')" @click="closeMenu()" v-smooth-scroll>{{$t('menu.we')}}</a></li> -->
                  <li class="customLink"><router-link :to="{name: 'Home', hash:'#nosotros'}" @click.native="closeMenu()">{{$t('menu.we')}}</router-link></li>
                  <!-- <li class="customLink"><a href="#travel-work" @click="closeMenu()" v-smooth-scroll>{{$t('menu.travel work')}}</a></li> -->
                  <li class="customLink"><router-link :to="{name: 'Home', hash:'#travel-work'}" @click.native="closeMenu()">{{$t('menu.travel work')}}</router-link></li>
                  <li class="customLink"><router-link :to="{name: 'Blog'}" @click.native="closeMenu()">Knokfanzine</router-link></li>
                  <!-- <li class="customLink"><a href="#contact" @click="closeMenu()" v-smooth-scroll>{{$t('menu.contact')}}</a></li> -->
                  <li class="customLink"><router-link :to="{name: 'Home', hash:'#contact'}" @click.native="closeMenu()">{{$t('menu.contact')}}</router-link></li>
                  <li v-if="!isInBlog" class="lang customLink"><a :class="{active: (lang === 'en')}" href="#" @click="changeLanguage('en')">EN</a></li>
                  <li class="lang customLink"><a :class="{active: (lang === 'es')}" href="#" @click="changeLanguage('es')">ES</a></li>
              </ul>
              <div class="mobile-credits mobile">
                <a href="http://instagram.com/mundoknoknok" target="_blank" class="instagram-social d-flex align-center customLink">
                  <InstagramIcon class="instagram-icon"></InstagramIcon><span>mundoknoknok</span>
                </a>
                <span class="copyrights">®All Rights Reserved . 2021 Buenos Aires Argentina. <br>Development by <a href="https://daptee.com.ar" target="_blank">Daptee</a></span>
              </div>
            </div>
        </div>
    </header>
</template>
<script>
import Logo from '../assets/img/knoknok_logo.svg?inline'
import Helper from '../helpers/Helper'
import ToggleMenu from '../components/ToggleMenu'
import InstagramIcon from '../assets/img/instagram-icon.svg?inline'
import { EventBus } from '../event-bus'
export default {
  name: 'Header',
  data () {
    return {
      lang: 'es',
      showLogo: false,
      isInBlog: false,
      showMobileNav: false,
      showNavbar: true
    }
  },
  components: {
    Logo,
    ToggleMenu,
    InstagramIcon
  },
  methods: {
    changeLanguage (lang) {
      this.$emit('changeLanguage', lang)
      this.lang = lang
    },
    showLogoHeader () {
      window.addEventListener('scroll', () => {
        this.showLogo = window.scrollY > window.screen.height || this.showMobileNav
      })
    },
    showMobileNavbar () {
      this.showMobileNav = !this.showMobileNav
    },
    checkIsInlog (url) {
      const pathParts = url.split('/')
      const finalPath = pathParts[(pathParts.length - 1)]
      this.isInBlog = false
      if (finalPath !== 'es' && finalPath !== 'en' && finalPath !== '') {
        this.isInBlog = Helper.isInURL(finalPath)
      }
    },
    closeMenu () {
      if (this.showMobileNav) {
        this.showMobileNav = !this.showMobileNav
      }
    }
  },
  mounted () {
    this.showLogoHeader()
    this.checkIsInlog(window.location.href)

    EventBus.$on('showNavbar', (show) => {
      this.showNavbar = show
    })
  },
  watch: {
    $route: {
      handler (to, from) {
        this.showLogoHeader()
        this.checkIsInlog(to.path)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header{
    z-index: 10;
    background-color: transparent;
    position: fixed;
    width: 100%;
    height: 80px;
    padding: 2rem 0;
    transition: all 0.3s ease-in-out;
    &.sticky{
      background-color: #000000;
      padding: 1rem 0;
    }
    .header-container{
        align-items: center;
        justify-content: space-between;
        .nav-container{
          &.desktop{
            padding-bottom: 0.5rem;
          }
          &.mobile-nav{
              display: flex!important;
              flex-direction: column;
              position: fixed;
              background-color: #000000;
              padding-top: calc(80px + 2rem);
              top: 0;
              left: 0;
              width: 100%;
              height: 100vh;
              transition: all 0.3s ease-in-out;
              .nav{
                max-height: calc(100vh - 240px);
                overflow: scroll;
              }
              li{
                border-top: .5px solid #ffffff4c;
                line-height: 5rem;
                padding: 0 3.2rem;
                a {
                  font-size: 20px;
                  &.active{
                    display: none;
                  }
                }
              }
            }
        }
        .logo-container{
            height: calc(80px - 2rem);
            padding: 1rem;
            padding-left: 0px;
            mix-blend-mode: difference;
            z-index: 1;
            .logo{
                transition: all .5s ease-in-out;
                height: 100%;
                display: block;
            }
        }
        .mobile-credits{
          position: fixed;
          bottom: 0;
          width: calc(100% - 3.2rem);
          left: 0;
          padding-left: 3.2rem;
          margin-bottom: 2rem;
          background-color: #000000;
          z-index: 1;
          a{
            color: #ffffff;
          }
          .instagram-social{
            margin-bottom: 1rem;
            .instagram-icon{
              width: 20px;
              margin-right: 1rem;
            }
          }
          .copyrights{
            font-size: 12px;
            opacity: 0.6;
          }
        }
        .nav{
            li{
                padding: 0 1rem;
                a{
                    color: rgba(256,256,256,0.8);
                    position: relative;
                    transition: all 0.3s ease-in-out;
                    &:after{
                        content: '';
                        position: absolute;
                        background-color: $main-color;
                        width: 4px;
                        height: 4px;
                        border-radius: 4px;
                        left: calc(50% - 2px);
                        top: 30px;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }
                    &:hover{
                        color: white;
                    }
                    &:hover:after, &.active:after{
                        opacity: 1;
                        top: 25px;
                    }
                }
                &.lang:last-child{
                    padding-left: 0px;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px){
  .header{
    padding: 1rem 0;
    .header-container{
      .logo-container{
        padding: 1rem 0;
      }
      .nav{
        li{
          &.lang:last-child{
            padding-left: 3.2rem;
            border-top: none;
          }
        }
      }
    }
  }
}
</style>
