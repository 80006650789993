<template>
    <section id="we">
        <div class="row flex-m-col">
            <div class="col-6 col-first-left">
              <div class="parallax-container d-flex align-center justify-start image1">
                <img v-parallax="0.1" src="../assets/img/campo.jpg" width="100%">
              </div>
              <div class="parallax-container d-flex align-center justify-center image2">
                <img v-parallax="0.1" src="../assets/img/we-image-3.jpg">
              </div>
            </div>
            <div class="col-6 col-first-right">
              <div class="parallax-container d-flex align-center justify-center image3">
                <img v-parallax="0.1" src="../assets/img/faro.jpg" width="100%">
              </div>
                <h3 id="nosotros" class="we-title-big dmserif">We!</h3>
            </div>
        </div>
        <div class="row wrapper space-left text-content">
            <div class="consulting-brand d-flex flex-column text-center">
                <span>{{$t('we.consulting')}}</span>
                <p>{{$t('we.goal')}}</p>
            </div>
        </div>
        <div class="row carousel-gallery">
            <CarouselGallery></CarouselGallery>
        </div>
        <div class="wrapper space-left about text-content">
            <h3 class="name-title-big dmserif text-center">Geo Vezzani</h3>
            <transition name="fadeIn">
              <div class="row flex-column text-center" v-if="!showEventsList">
                  <span>{{$t('we.about text1')}} <br><br></span>
                  <p>{{$t('we.about text2')}} <br><br></p>
                  <p><strong>{{$t('we.about text3')}}</strong></p>
              </div>
            </transition>
            <transition name="fadeIn">
              <ul v-if="showEventsList" class="events-list text-center">
                  <li class="events" v-for="(item, index) in eventsListItems" :key="index"><span>{{ item.description }}</span></li>
              </ul>
            </transition>
              <!-- <div class="row p-relative justify-center">
                <div class="button-container"  @click="showEventsList = !showEventsList">
                  <MagneticButton id="we-button" :size='50' :distance='8' text="H"></MagneticButton>
                </div>
              </div> -->
        </div>
    </section>
</template>
<script>
import CarouselGallery from '@/components/Carousel'
// import MagneticButton from '../components/MagneticButton'
export default {
  name: 'We',
  components: {
    CarouselGallery
    // MagneticButton
  },
  data () {
    return {
      showEventsList: false,
      eventsListItems: [
        {
          description: 'Descripcion del evento item 1'
        },
        {
          description: 'Descripcion del evento item 2'
        },
        {
          description: 'Descripcion del evento item 3'
        },
        {
          description: 'Descripcion del evento item 4'
        },
        {
          description: 'Descripcion del evento item 5'
        },
        {
          description: 'Descripcion del evento item 6'
        },
        {
          description: 'Descripcion del evento item 7'
        },
        {
          description: 'Descripcion del evento item 8'
        },
        {
          description: 'Descripcion del evento item 9'
        },
        {
          description: 'Descripcion del evento item 10'
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
#we{
    background-color: #000000;
    padding: 8rem 0;
    .col-first-left{
        // margin-top: -22rem;
        .image1{
          width: 90%;
          margin: 0 10% 0 0;
        }
        .image2{
            width: calc(50vw - 10rem);
            max-height: 450px;
            float: right;
            margin: 6rem 10% 0 0;
        }
    }
    .col-first-right{
        margin-top: 10rem;
        .image3{
          width: 100%;
        }
        .we-title-big{
            font-size: 250px;
            margin-top: 4rem;
            text-transform: inherit;
        }
    }
    .parallax-container{
      overflow: hidden;
    }
    .consulting-brand{
        margin-top: 2rem;
        span{
            font-size: 30px;
        }
        p{
            margin-top: 2rem;
            font-size: 18px;
        }
    }
    .carousel-gallery{
        margin: 8rem 0 6rem;
        cursor: grab;
    }
    .about{
        margin-top: 4rem!important;
        .name-title-big{
            font-size: 100px;
            margin-bottom: 4rem;
            text-transform: inherit;
        }
        span{
            font-size: 30px;
        }
        p{
            font-size: 18px;
        }
        .button-container{
            top: 200px;
            left: 100px;
            margin-top: 2rem;
            #we-button{
              background-color: #ffffff;
              color: #000000;
            }
        }
        .events-list{
            .events{
              span{
                font-size: 20px;
                line-height: 1.8;
              }
            }
        }
    }
}
@media only screen and (max-width: 800px){
  #we{
    .col-first-left{
      margin-top: -15rem;
      .image1{
        width: 100%;
        margin: 0;
      }
      .image2{
        width: 100%;
        margin: 2rem 0;
      }
    }
    .col-first-right{
      margin-top: 0rem;
      .we-title-big{
        font-size: 150px;
        text-align: center;
      }
    }
    .col-first-left img, .col-first-right img{
      width: 100%;
    }
    .consulting-brand{
      span, p{
        font-size: 22px;
      }
    }
    .about{
      .name-title-big{
        font-size: 60px;
      }
      span{
        font-size: 22px;
      }
      p{
        font-size: 16px;
      }
    }
  }
}
</style>
