<template>
    <div class="welcome-container d-flex">
        <KnokWelcomeLogo class="welcome-logo"></KnokWelcomeLogo>
    </div>
</template>

<script>
import KnokWelcomeLogo from '../assets/img/knoknok-welcome.svg?inline'
export default {
  name: 'Welcome',
  components: {
    KnokWelcomeLogo
  }
}
</script>
<style scoped lang="scss">
.welcome-container{
    position: fixed;
    top:0;
    left: 0;
    padding: 4rem;
    width: calc(100% - 8rem);
    height: calc(100vh - 8rem);
    z-index: 100;
    background-color: #000000;
    align-items: center;
    justify-content: center;
    .welcome-logo{
        max-width: 500px;
        animation: pulse 2s ease-in-out infinite;
        -webkit-animation: pulse 2s ease-in-out infinite;
        -o-animation: pulse 2s ease-in-out infinite;
        -moz-animation: pulse 2s ease-in-out infinite;
    }
}
</style>
