<template>
    <a :id="id" class="magnetic-button d-flex justify-center align-center customLink">{{ text }}</a>
</template>

<script>
export default {
  name: 'MagneticButton',
  props: ['text', 'size', 'distance', 'id'],
  data () {
    return {
      distanceButton: this.distance,
      sizeButton: this.size,
      mouseHasEntered: false,
      mouseIsInButtonTerritory: false
    }
  },
  methods: {
    stylingButton () {
      this.button.style.width = this.sizeButton + 'px'
      this.button.style.height = this.sizeButton + 'px'
    },
    init () {
      this.button = document.querySelector('#' + this.id)
      let {
        width,
        height,
        x: centerPointX,
        y: centerPointY
      } = this.button.getBoundingClientRect()
      centerPointX = centerPointX + width / 2
      centerPointY = centerPointY + height / 2
      this.centerPointX = centerPointX
      this.centerPointY = centerPointY
    },
    handleEvent () {
      window.addEventListener('mousemove', (e) => this.handleMove(e))
      window.addEventListener('scroll', () => this.init())
    },
    handleMove (e) {
      const x = e.x
      const y = e.y

      const leftBorderLine = this.centerPointX - this.distanceButton
      const rightBorderLine = this.centerPointX + this.distanceButton
      const topBorderLine = this.centerPointY - this.distanceButton
      const bottomBorderline = this.centerPointY + this.distanceButton
      this.xWalk = (x - this.centerPointX) / 2
      this.yWalk = (y - this.centerPointY) / 2

      this.mouseIsInButtonTerritory = x > leftBorderLine && x < rightBorderLine && y > topBorderLine && y < bottomBorderline

      if (this.mouseIsInButtonTerritory) {
        if (!this.mouseHasEntered) {
          this.distanceButton = this.distance + 80
          this.mouseHasEntered = true
        }
        this.handleCatch()
      } else {
        this.handleReset()
      }
    },
    handleCatch () {
      this.button.style.transform = `translate(${this.xWalk}px, ${this.yWalk}px)`
      this.button.style.cursor = 'pointer'
    },
    handleReset () {
      this.button.style.transform = `translate(${0}px, ${0}px)`
      if (this.mouseHasEntered) this.distanceButton = 80
      this.mouseHasEntered = false
    }
  },
  mounted () {
    this.init()
    this.stylingButton()
    this.handleEvent()
  }
}
</script>
<style scoped lang="scss">
.magnetic-button{
    border-radius: 50%;
    transition: 0.2s transform;
    color: #000000 ;
    background-color: #ffffff;
    font-weight: bold;
}
</style>
