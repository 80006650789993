<template>
  <div class="home">
    <Hero></Hero>
    <We></We>
    <TravelWork></TravelWork>
    <Footer></Footer>
  </div>
</template>

<script>
import Hero from '../components/Hero'
import We from '../components/We'
import TravelWork from '../components/TravelWork'
import Footer from '../components/Footer'

export default {
  name: 'Home',
  components: {
    Hero,
    We,
    TravelWork,
    Footer
  }
}
</script>
