export default class Helper {
  static getCurrentLocale () {
    return Helper.getLocaleFromUrl() || Helper.getLocalFromNavigator()
  }

  static getLocalFromNavigator () {
    return navigator.language.split('-')[0]
  }

  static getLocaleFromUrl () {
    const parts = window.location.href.split('/')
    return (parts[3] === 'es' || parts[3] === 'en') ? parts[3] : null
  }

  static isInURL (name) {
    const url = window.location.href
    const partes = url.split('/')
    return partes.includes(name)
  }

  static isInPage (page) {
    page = page || ''
    const url = window.location.href
    const partes = url.split('/')
    return partes[(partes.length - 1)].toLowerCase() === page.toLowerCase()
  }
}
